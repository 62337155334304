@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply Tailwind's base font size to the html element */
html {
  @apply text-base;
}

/* Override default tailwind collapse definition to make sure menus are shown */
.collapse {
  visibility: visible;    
}

/* .style2 .section-title span, .section-title.style2 span {
  @apply bg-gray-50 !important;
} */

.swiper-slide, .swiper-wrapper {
  @apply bg-gray-50 !important;
}

.product-card {
  @apply bg-white border-r border-b md:border border-gray-550;
}

.btn-thin {
  @apply bg-gray-50 !important;
}

input[type="file"]{
  @apply h-10 !important;
}

/* news page back button */
.btn-blue {
  @apply bg-blue-dark text-white hover:bg-opacity-80 hover:text-white
}

/* add rounding to images in rich text */
.rich-text img {
  @apply rounded drop-shadow inline-block;
}

/* add larger text font to rich text h3 */
.rich-text h3 {
  @apply text-xl;
}

/* Rich text formats */
.rich-text hr {
  @apply my-5 border border-gray-550;
}

.rich-text {
  h2 {
    @apply text-2xl font-semibold my-2;
  }

  h3 {
    @apply text-xl font-semibold my-2;
  }
}

/* checkout page nav */
.checkout-nav {
  .inactive > div {
    @apply bg-gray-600;
  }
  .inactive > a {
    @apply text-gray-700;
  }  
}

.checkout-nav .active {
  @apply bg-blue text-white;

  > div:first-child {
    @apply text-white bg-blue-dark;
  }

  > a {
    @apply text-white hover:text-white
  }

  .indicator {
    @apply absolute -bottom-2.5 left-1/2 transform -translate-x-1/2 border-l-transparent border-r-transparent border-t-blue border-solid border-l-[10px] border-r-[10px] border-t-[10px]
  }
}

/* Modal (bootstrap 3.3.5) overrides */
.modal-content {
  @apply border-0;
}

.modal-dialog {
  @apply bg-white max-w-[960px] overflow-auto w-full;
}

.modal-header {
  @apply border-b border-gray-300 text-white px-5 py-2 mb-5;
}

.modal-content {
  @apply border-0 rounded-none shadow-md;
}

.modal-footer {
  @apply bg-gray-100 border-t border-gray-300 py-2 px-5;
}

.modal-title-site {
  @apply text-white font-oswald text-3xl font-light py-3 uppercase;
}

.modal {
  @apply overflow-auto;
}

.signUpContent .modal-dialog {
  @apply max-w-[460px];
}

.modal-dialog.modal-xs {
  @apply max-w-[460px];
}

#product-details-modal .modal-dialog {
  @apply bg-white max-w-[960px] overflow-auto w-full;
}

.modal-dialog .product-share {
  @apply border-t-0 mt-0;
}

.modal-dialog .modal-product-thumb {
  @apply pt-4;
}

/* Main styles */
a {
  @apply text-alabaster-800 no-underline underline-offset-2 !outline-none transition-all duration-200 ease-in;
}

a:hover, a:focus {
  @apply no-underline cursor-pointer;  
}

/* Search results */
.search-result {
  @apply py-4 grid grid-cols-1 md:grid-cols-2 grid-rows-2 gap-4;  
}

/* Product card */
.product-card {
  @apply hover:drop-shadow-md;
}

/* Override bootstrap 3.3.5 button text size */
.btn {
  @apply !text-base !no-underline;
}

#client-registration {
  a {
    @apply underline underline-offset-2;
  }
}

[data-role="product-tabs"] {
  .active {
    @apply bg-gray-700 text-white;
  }  
}

/* Section titles */
.section-title {
  @apply relative leading-tight text-3xl md:text-4.5xl uppercase font-thin my-3 px-2 md:px-20 z-[100] !bg-gray-50 text-alabaster-800 border-none;

  a, span {
    @apply bg-gray-50 md:px-4;
  } 
}

@screen md {
    .section-title::after {
      @apply bg-alabaster-200 content-[''] block h-px ml-1.5 absolute top-7 -z-10 w-4/5;
  }
}

.social-responsibility-grid {
  @apply grid grid-cols-4 md:grid-rows-2 md:grid-flow-col gap-8 my-6;
}