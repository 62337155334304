

.search-full, .search-input {
    background: #2980b9;
}

.search-close {
    color: #2980b9;
}

.btn-primary, .btn-primary, .product:hover .add-fav:hover, .product:hover .add-fav.active {
    background-color: #3498db;
    color: #FFFFFF;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary, .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #39a7f1;
    border-color: #39a7f1;
    color: #FFFFFF;
}

.btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
    background: #2c88c5;
}

.navbar-tshop {
    background: #3498db;
}

.nav-sale {
    background-color: rgb(41, 128, 185);
}

.client-takeover .nav-sale {
    background: #f74040;
}

.client-takeover .navbar-tshop {
    background: #f74040;
}

.client-takeover .nav > li:hover > a {
    background: #b71212 !important;
}

.navbar-top {
    background: #2c88c5;
}

.site-color {
    color: #3498db !important;
}

.nav > li.active, 
.nav > li > a:hover, 
.nav > li > a:focus, 
.nav > li:hover > a {
    background: #2980b9;
}

.nav .open > a, 
.nav .open > a:hover, 
.nav .open > a:focus, 
.cartMenu:hover > a.dropdown-toggle {
    background: #2980b9;
    background-color: #2980b9 !important;
}

.dropdown-menu {
    border-top: solid #2980b9;
}

.search-box:hover .form-control {
    background: #3498db;
}

.search-box:hover .btn-nobg {
    color: #3498db;
}

.searchInputBox .search-btn {
    color: #fff;
}

.mCS-dark-2 > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: rgba(52, 152, 219, 0.7) !important;
}

.action-control a:hover, .action-control a.active {
    color: #fff;
    background: #2980b9;
}

.product:hover, .featuredImgLook2 .inner:hover {
    border: 1px solid #3498db;
}

.subCategoryList .thumbnail:hover {
    border: 1px solid #3498db;
}

.pager2 a {
    border: 1px solid #3498db;
}

.pager2 a.selected {
    background: #3498db;
}

.btn-discover, .btn-site, .newsLatterBox .btn {
    background: #3498db;
}

.btn-discover:hover, .btn-site:hover, .newsLatterBox .btn:hover {
    background: #2980b9;
}

.full {
    width: 100%;
}

.footer a:hover {
    color: #3498db;
}

.footer {
    /*border-top: solid 3px #3498db;*/
}

.modal-header {
    background: #3498db;
}

.form-control:focus {
    border-color: #3498db;
}

.new-product {
    background: rgba(52, 152, 219, 0.95) !important;
}

#pager a.cycle-pager-active {
    background: rgba(52, 152, 219, 0.5);
}

/* tree menu */

.nav.tree > li.active, .nav.tree > li > a:hover, .nav.tree > li > a:focus, .nav.tree > li:hover > a {
    background: none;
    color: #2a2a2a;
}

.nav.tree .open-tree > a, .nav.tree .open-tree > a:hover, .nav.tree .open-tree > a:focus {
    background: none;
    font-weight: bold;
    color: #2a2a2a;
}

.panel-default > .panel-heading {
    border-top: 4px solid #3498db;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus, .nav-tabs > li:hover > a {
    background: #BFC9CA;
}

.nav-tabs {
    border-bottom: 3px solid #BFC9CA;
}

.socialIcon a:hover {
    background: #3498db;
}

.cart-actions button, .cart-actions .wishlist {
    background: none repeat scroll 0 0 #3498db;
    border: 2px solid #2980b9;
}

.cart-actions button:hover {
    background: #fff;
    color: #2a2a2a;
    border: solid 2px #3498db;
}

.sp-thumbs a:hover, .sp-thumbs a:active, .sp-current, sp-current:visited {
    border: 2px solid #3498db !important;
}

.promo-1 {
    background: none repeat scroll 0 0 #2980b9;
}

@media (max-width: 767px) {
    .miniCartFooter {
        background: #2980b9 !important;
    }
}

#pager2 span.cycle-pager-active {
}

#pager2 span.cycle-pager-active {
    background: #2980b9 !important;
}

#pager2 span {
    border: 2px solid #2980b9
}

.orderStep li.active a {
    background: #3498db;
}

.orderStep li.active a:after {
    border-top-color: #3498db !important;
}

.orderStep li a {
    background: #2c88c5;
}

darkcoilor {
    background: #2c88c5;
}

citecolor {
    color: #3498db !important;
}
