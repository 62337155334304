.flipbook-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.71);
    z-index: 2147483646 !important;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.flipbook-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    text-shadow: none;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #ffffff;
}

.flipbook-wrapper-transparent {
    width: 100%;
    height: 100%;
    background: none;
}

.flipbook-main-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    direction: ltr !important;
    scrollbar-color: rgba(130, 130, 130, 0.5) transparent;
    scrollbar-width: thin;
}

.flipbook-bookLayer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    cursor: -webkit-grab;
    cursor: grab;
}

.flipbook-zoomLayer-inside {
    display: block;
    position: relative;
    background-size: contain;
    text-align: center;
}

.flipbook-zoomLayer-inside .flipbook-carousel-page {
    float: left;
}

.flipbook-book {
    display: block;
    position: relative;
}

.flipbook-page {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateZ(0px);
    will-change: transform;
    transform-style: preserve-3d;
}

.flipbook-page3 {
    position: absolute;
    background: #eee;
    will-change: transform;
    transform: rotateY(var(--page3-rotate-y, 0));
    transform-origin: 0 50%;
    -webkit-transform-origin: 0 50%;
}

.flipbook-page3-html {
    position: absolute;
    height: 1000px;
    transform-origin: 0 0;
    top: 0;
    left: 0;
}

.flipbook-page3-inner {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    will-change: opacity;
}

.flipbook-page3-inner-back {
    transform: scaleX(-1);
}

.flipbook-page3-bg {
    width: 100%;
    height: 100%;
    position: absolute;
}

.flipbook-page3-bg img {
    height: 100% !important;
}

.flipbook-page3 img {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    margin: auto;
    position: absolute;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    float: left;
    user-select: none;
    pointer-events: none;
    max-width: unset;
}

.flipbook-page3 canvas {
    width: 100%;
    height: 100%;
}

.flipbook-page3::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 1);
    opacity: var(--page3-shadow-opacity, 0);
    will-change: opacity;
    transform: translateZ(0);
    z-index: 1;
    pointer-events: none;
}

.flipbook-page3-front {
    transform-origin: 0 50%;
    -webkit-transform-origin: 0 50%;
}

.flipbook-page3-back {
    transform-origin: 0% 50%;
    -webkit-transform-origin: 0% 50%;
}

.flipbook-page3-back .flipbook-page3-inner {
    transform: rotateY(180deg);
}

.flipbook-book3 {
    display: block;
    position: relative;
}

.flipbook-center-container3 {
    position: relative;
    will-change: transform;
    border: none;
}

.flipbook-page-htmlContent {
    transform-origin: 0 0;
    z-index: 50;
    position: absolute;
}

.flipbook-page-html video,
.flipbook-page-html iframe {
    max-width: initial;
}

.flipbook-book canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

.flipbook-page img {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

.flipbook-left-arrow {
    display: block !important;
    position: absolute !important;
    top: 50% !important;
    left: 0;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    cursor: pointer;
}

.flipbook-right-arrow {
    display: block !important;
    position: absolute !important;
    top: 50% !important;
    right: 0;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    cursor: pointer;
}

.flipbook-first-arrow {
    display: block !important;
    position: absolute !important;
    top: 50%;
    left: 0;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    cursor: pointer;
}

.flipbook-last-arrow {
    display: block !important;
    position: absolute !important;
    top: 50%;
    right: 0;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    cursor: pointer;
}

.flipbook-currentPageHolder {
    position: relative;
    z-index: 3;
    pointer-events: auto;
    display: inline-block;
    user-select: none;
    display: flex;
    align-items: center;
    box-sizing: content-box;
    width: max-content;
}

.flipbook-currentPageHolder form {
    display: flex;
    top: 0;
    margin: 0;
}

.flipbook-currentPageNumber {
    border-radius: 15px !important;
    padding: 5px !important;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
    font-size: 14px;
    line-height: initial;
}

.flipbook-currentPageInput {
    line-height: 14px !important;
    background: none !important;
    border: none !important;
    font-size: 14px !important;
    max-width: initial !important;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
    text-align: right !important;
    height: 100% !important;
    padding: 0 !important;
    border: 2px solid rgba(128, 128, 128, 0.5);
}

.flipbook-currentPageInput.digits-3 {
    min-width: calc(3em) !important;
}

.flipbook-currentPageInput.digits-2 {
    min-width: calc(2em) !important;
}

.flipbook-currentPageInput.digits-1 {
    min-width: calc(1em) !important;
}

.flipbook-currentPageInput:focus {
    outline: 2px solid #88888885;
}

.flipbook-color-transparent {
    color: transparent !important;
}

.flipbook-browser-fullscreen {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 999999 !important;
    margin: 0 !important;
    padding: 0 !important;
}

.flipbook-menuBottom {
    position: absolute;
    bottom: 0;
    margin: 0;
    padding: 0;
    z-index: 2;
    pointer-events: none;
    display: flex;
    overflow: visible;
}

.flipbook-menu-btn-wrapper {
    display: inline-block;
    position: relative;
    overflow: visible;
}

.flipbook-tools .flipbook-menu-btn-wrapper {
    pointer-events: auto;
    display: flex;
    align-items: center;
    position: relative;
    overflow: visible;
    cursor: pointer;
    padding-right: 15px;
    min-width: 130px;
}

.flipbook-share .flipbook-menu-btn-wrapper {
    pointer-events: auto;
    display: inline-block;
    align-items: center;
    position: relative;
    overflow: visible;
    cursor: pointer;
    padding: 15px;
}

.flipbook-menuBottom .flipbook-has-tooltip::before {
    bottom: calc(100% + 5px);
}

.flipbook-menuTop .flipbook-has-tooltip::before {
    top: calc(100% + 5px);
}

.flipbook-has-tooltip::before {
    content: attr(data-tooltip);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.4;
    text-transform: none;
    width: max-content;
    white-space: nowrap;
    transition:
        opacity 0.3s ease,
        visibility 0.3s ease;
}

.flipbook-menu-right .flipbook-has-tooltip:last-child::before {
    left: 100%;
    transform: translateX(-100%);
}

.flipbook-menu-left .flipbook-has-tooltip:first-child::before {
    left: 0%;
    transform: translateX(0%);
}

.flipbook-has-tooltip:hover::before {
    visibility: visible;
    opacity: 1;
}

.flipbook-has-tooltip.disabled:hover::before {
    visibility: hidden;
    opacity: 0;
}

.flipbook-menu-fixed {
    left: 0;
    right: 0;
}

.flipbook-menu-fixed .flipbook-menu {
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .flipbook-menu-fixed .flipbook-menu {
        justify-content: space-around;
        width: -webkit-fill-available;
    }
}

.flipbook-menu-center {
    justify-content: center;
    align-items: center;
}

.flipbook-menu-right {
    justify-content: flex-end;
    align-self: center;
    flex: 1;
    display: flex;
    /* min-width: -webkit-min-content; */
    /* Workaround to Chrome bug */
}

.flipbook-menu-left {
    justify-content: flex-start;
    align-self: center;
    flex: 1;
    display: flex;
    /* min-width: -webkit-min-content; */
    /* Workaround to Chrome bug */
}

.flipbook-menu-floating {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: fit-content;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.flipbook-menuTop {
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 2;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    overflow: visible;
}

.flipbook-menu {
    position: relative;
    text-align: center;
    display: flex;
}

.flipbook-menu svg,
.flipbook-nav svg {
    box-sizing: content-box;
}

.flipbook-menu-btn {
    user-select: none;
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    background: none;
    transition: all 0.1s ease-out;
    pointer-events: auto;
    box-sizing: content-box;
}

.flipbook-submenu .flipbook-menu-btn {
    justify-content: left;
}
.flipbook-submenu .flipbook-menu-btn svg {
    margin-right: 10px;
}

.flipbook-nav .disabled,
.flipbook-menu .disabled {
    opacity: 0.2;
    cursor: inherit;
    /* pointer-events: none; */
}

.flipbook-icon-reverse {
    transform: scaleX(-1);
    transform-origin: center;
}

.flipbook-menu .flipbook-findbar {
    pointer-events: auto;
    margin-top: 10px;
}

.flipbook-menu .flipbook-findbar input {
    width: 140px;
    margin-left: 0;
}

.flipbook-thumbHolder {
    bottom: 0;
    position: absolute;
    top: 0;
    z-index: 9;
    min-width: 170px;
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
}

.flipbook-thumbsWrapper {
    bottom: 0;
    left: 0;
    top: 90px;
    overflow: auto;
    width: 100%;
    height: 100%;
}

.flipbook-thumbsScroller {
    margin: 0;
    position: relative;
    text-align: center;
    display: grid;
    justify-content: center;
}

.flipbook-thumbs-grid .flipbook-thumbsWrapper {
    width: calc(100% - 100px);
    padding: 50px;
}

.flipbook-thumbs-grid .flipbook-thumbsScroller {
    display: block;
}
.flipbook-thumbs-grid {
    background-color: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(20px);
    width: 100%;
    color: white;
}

.flipbook-thumbs-grid .flipbook-menu-title,
.flipbook-thumbs-grid .flipbook-btn-close svg {
    color: white !important;
    fill: white !important;
}

.flipbook-thumbs-grid .flipbook-menu-header {
    display: none;
}

.flipbook-thumbs-grid-close {
    display: none;
}

.flipbook-thumbs-grid .flipbook-thumbs-grid-close {
    display: block;
    position: absolute;
    top: 10px;
    right: 40px;
    z-index: 1;
}

.flipbook-thumbs-grid .flipbook-thumbs-grid-close svg {
    font-size: 30px;
    fill: #fff !important;
    text-shadow: drop-shadow(rgb(0, 0, 0) 0px 0px 1px);
    padding: 10px;
}

.flipbook-thumb {
    margin: 15px 10px 10px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgb(0 0 0 / 25%);
}

.flipbook-thumb:hover,
.flipbook-thumb-active {
    border: 1px solid rgb(0 0 0 / 50%);
    box-shadow: 0 0 0px 2px rgb(0 0 0 / 25%);
    transition: 0.2s all;
    opacity: 0.7;
}

.flipbook-thumb:hover .flipbook-thumb-num {
    background: rgba(0, 0, 0, 0.8);
    transition: 0.2s all;
}

.flipbook-search {
    padding: 16px;
    position: relative;
}

.flipbook-search-clear {
    position: absolute;
    top: 7px;
    right: 7px;
}

.flipbook-search-match {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    width: 218px;
}

.flipbook-search-match:hover {
    text-decoration: underline;
}

.flipbook-search-match-title {
    height: 20px;
}

.flipbook-search-match-text {
    text-align: left;
}

.flipbook-thumb-num {
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    line-height: 14px;
    user-select: none;
    font-weight: 600;
    position: relative;
    position: absolute;
    bottom: 11px;
    bottom: 2px;
    right: 2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.6);
    color: white !important;
    padding: 4px 6px;
    display: inline-block;
}

.flipbook-thumb img {
    display: inline-block;
}

.flipbook-toc {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50px;
    bottom: 0;
    overflow: auto;
    width: calc(100% - 20px);
    padding: 0 10px;
    box-sizing: content-box;
}

.flipbook-toc ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}
.flipbook-toc .title-container {
    display: flex;
    align-items: center;
    flex: 1;
}

.flipbook-toc ul ul {
    padding-left: 20px; /* Indent only nested lists */
}

.flipbook-toc li {
    margin: 5px 0;
}

.flipbook-toc .toc-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.flipbook-toc .toc-item:hover {
    background-color: rgba(128, 128, 128, 0.2);
}

.flipbook-toc .expand-icon,
.flipbook-toc .spacer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1em; /* Fixed width */
    height: 1em; /* Fixed height to match icon size */
    margin-right: 8px;
    flex-shrink: 0;
}

.flipbook-toc .expand-icon svg {
    transition: transform 0.2s ease-in-out;
}

.flipbook-toc .expand-icon.expanded svg {
    transform: rotate(90deg);
}

.flipbook-toc .spacer {
    visibility: hidden;
}

.flipbook-toc .title-container span.title {
    font-size: 14px;
    /* color: #333; */
}

.flipbook-toc .page-number {
    font-size: 12px;
    margin-left: 10px;
    white-space: nowrap;
    margin-right: 5px;
}

.flipbook-tocHolder {
    position: absolute;
    top: 0;
    width: 250px;
    bottom: 0;
    z-index: 9;
}

.flipbook-tocItem {
    display: block;
}

.flipbook-tocItem {
    display: flex;
    justify-content: space-between;
    padding: 8px 15px; /* Adjusted padding */
    margin: 5px 0; /* Optional for spacing between items */
    align-items: center; /* Ensure vertical alignment of content */

    cursor: pointer;
    font-size: 14px;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    width: 100%;
}

.flipbook-tocItem .flipbook-icon {
    margin-right: 10px; /* Add space between icon and title */
    display: inline-block;
}

.flipbook-tocItem .title {
    flex-grow: 1; /* Allow the title to take up remaining space */
    text-align: left; /* Ensure title aligns left */
    margin-left: 10px; /* Add some space between icon and title */
}

.flipbook-tocItem .flipbook-toc-number {
    text-align: right;
    width: 25px; /* Ensure the width remains fixed */
    flex-shrink: 0; /* Prevent the page number from shrinking */
}

.flipbook-tocItem .title:hover {
    text-decoration: underline;
}

.flipbook-tocItem .right {
    float: right;
}

.flipbook-tocItem > span {
    vertical-align: top;
}

.flipbook-tocItem > span > span {
    vertical-align: middle;
}

.flipbook-findbar {
    position: relative;
}

.flipbook-findbar input {
    width: 210px;
    padding: 5px;
    font-size: 14px;
    line-height: initial;
    border: 1px solid rgba(128, 128, 128, 0.7);
}

.flipbook-findbar input:focus {
    outline: 1px solid rgba(128, 128, 128, 0.7);
}

.flipbook-find-info {
    padding-left: 10px;
    max-width: 200px;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
    font-size: 14px;
    pointer-events: none;
}

/* .invisible {
    opacity: 0;
    pointer-events: none;
    display: none;
} */

/* .hidden {
    display: none !important;
} */

.flipbook-hidden {
    display: none !important;
}

.flipbook-overlay-visible {
    opacity: 1;
}

.transition {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.flipbook-share-email {
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='at' class='svg-inline--fa fa-at fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M256 8C118.941 8 8 118.919 8 256c0 137.059 110.919 248 248 248 48.154 0 95.342-14.14 135.408-40.223 12.005-7.815 14.625-24.288 5.552-35.372l-10.177-12.433c-7.671-9.371-21.179-11.667-31.373-5.129C325.92 429.757 291.314 440 256 440c-101.458 0-184-82.542-184-184S154.542 72 256 72c100.139 0 184 57.619 184 160 0 38.786-21.093 79.742-58.17 83.693-17.349-.454-16.91-12.857-13.476-30.024l23.433-121.11C394.653 149.75 383.308 136 368.225 136h-44.981a13.518 13.518 0 0 0-13.432 11.993l-.01.092c-14.697-17.901-40.448-21.775-59.971-21.775-74.58 0-137.831 62.234-137.831 151.46 0 65.303 36.785 105.87 96 105.87 26.984 0 57.369-15.637 74.991-38.333 9.522 34.104 40.613 34.103 70.71 34.103C462.609 379.41 504 307.798 504 232 504 95.653 394.023 8 256 8zm-21.68 304.43c-22.249 0-36.07-15.623-36.07-40.771 0-44.993 30.779-72.729 58.63-72.729 22.292 0 35.601 15.241 35.601 40.77 0 45.061-33.875 72.73-58.161 72.73z'%3E%3C/path%3E%3C/svg%3E");
}

.flipbook-icon {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    bottom: 0;
    text-align: center;
    display: flex;
    box-sizing: content-box !important;
    justify-self: left;
}

.flipbook-btn-close {
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flipbook-font {
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
}

.flipbook-btn {
    text-transform: uppercase;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    width: 200px;
    line-height: 32px;
    text-align: center;
    margin: 5px auto;
    border: none;
    background: #f9f9f9;
    background: none;
    border-radius: 3px;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    font-size: 12px;
}

.flipbook-bg-gradient {
    background: #313538dd;
}

.flipbook-color-gradient {
    color: #eee;
    fill: #eee;
}

.flipbook-color-gradient:hover {
    color: #eee;
    fill: #eee;
}

.flipbook-bg-dark {
    background: rgb(49, 53, 56);
}

.flipbook-color-dark {
    color: rgba(255, 255, 255, 0.75);
    fill: rgba(255, 255, 255, 0.75);
}

.flipbook-color-dark:hover {
    color: #fff;
    fill: #fff;
}

.flipbook-bg-light {
    /* background: rgb(255, 255, 255); */
}

.flipbook-color-light {
    color: rgba(50, 50, 50, 0.75);
    fill: rgba(50, 50, 50, 0.75);
}

.flipbook-color-light:hover {
    color: rgb(50, 50, 50);
    fill: rgb(50, 50, 50);
}

.flipbook-bg-twilight {
    background: #141414;
}

.flipbook-color-twilight {
    color: #feffd3;
    fill: #feffd3;
}

.flipbook-color-twilight:hover {
    color: #feffd3;
    fill: #feffd3;
}

.flipbook-bg-darkgrey {
    background: #212121;
}

.flipbook-color-darkgrey {
    color: #9e9e9e;
    fill: #9e9e9e;
}

.flipbook-color-darkgrey:hover {
    color: #bdbdbd;
    fill: #bdbdbd;
}

.flipbook-bg-lightgrey {
    background: #e0e0e0;
}

.flipbook-color-lightgrey {
    color: #757575;
    fill: #757575;
}

.flipbook-color-lightgrey:hover {
    color: #616161;
    fill: #616161;
}

.flipbook-sub-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.flipbook-sub-menu-center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.flipbook-menu-header {
    position: relative;
    padding: 10px;
    font-size: 16px;
    text-align: center;
}

.flipbook-menu-title {
    pointer-events: none;
    user-select: none;
}

.flipbook-sub-menu-content {
    display: inline-block;
    margin-top: -20px;
    min-width: 250px;
    padding-bottom: 10px;
}

.flipbook-carousel-slide {
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    position: absolute;
    overflow: hidden;
}

.flipbook-carousel-slide .slide-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.flipbook-carousel-page {
    display: inline-block;
    background: #f1f1f1;
    position: relative;
    -o-transform-origin: 0 0;
}

.flipbook-scroll-page {
    display: block;
    background: #f1f1f1;
    position: relative;
}

.flipbook-carousel-page-inner,
.flipbook-scroll-page-inner {
    -webkit-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
    margin: 0;
    padding: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.flipbook-carousel-page-inner canvas,
.flipbook-scroll-page-inner {
    width: 100%;
    height: 100%;
}

.flipbook-carousel-page img,
.flipbook-scroll-page img {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    position: absolute;
    pointer-events: none;
    user-select: none;
}

.flipbook-carousel-page .page-carousel-img,
.flipbook-scroll-page .page-scroll-img {
    height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.flipbook-overflow-hidden::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.flipbook-overflow-hidden {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    overflow: hidden;
}

/*share*/

.flipbook-share .social.bottom,
.flipbook-share .social.top {
    -webkit-transform-origin: 0 0;
    -o-transform-origin: 0 0;
}

.flipbook-share {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flipbook-share [class*='entypo-']:before {
    font-family: entypo, sans-serif;
}

.flipbook-share label {
    font-size: 16px;
    cursor: pointer;
    margin: 0;
    padding: 5px 10px;
    border-radius: 5px;
    background: #a29baa;
    color: #333;
    transition: all 0.3s ease;
}

.flipbook-share label:hover {
    opacity: 0.8;
}

.flipbook-share label span {
    text-transform: uppercase;
    font-size: 0.9em;
    font-family: Lato, sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    padding-left: 6px;
}

.flipbook-share ul {
    position: relative;
    left: 0;
    right: 0;
    height: 46px;
    color: #fff;
    margin: auto;
    padding: 0;
    list-style: none;
}

.flipbook-share ul li {
    font-size: 16px;
    cursor: pointer;
    width: 40px;
    margin: 0;
    padding: 9px 0;
    text-align: center;
    float: left;
    height: 22px;
    position: relative;
    z-index: 2;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flipbook-page-preloader {
    width: auto !important;
    height: auto !important;
}

.flipbook-page-preloader-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/** preloader*/

.cssload-container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    position: absolute;
    z-index: 2147483647 !important;
    pointer-events: none;
}

.cssload-speeding-wheel {
    width: 24px;
    height: 24px;
    /* margin: 0 auto; */
    border: 3px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    border-left-color: rgba(255, 255, 255, 0.2);
    border-right-color: rgba(255, 255, 255, 0.2);
    animation: cssload-spin 575ms infinite linear;
    -o-animation: cssload-spin 575ms infinite linear;
    -ms-animation: cssload-spin 575ms infinite linear;
    -webkit-animation: cssload-spin 575ms infinite linear;
    -moz-animation: cssload-spin 575ms infinite linear;
    top: 45%;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    z-index: 3;
}

@keyframes cssload-spin {
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes cssload-spin {
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes cssload-spin {
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes cssload-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes cssload-spin {
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/**new preloader end*/

.flipbook-loading-text {
    position: absolute;
    top: 45%;
    margin-top: 20px;
    width: 100%;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
    font-size: 14px;
    color: #aaa !important;
}

.flipbook-loading-bg {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    top: 45%;
    left: 50%;
    position: absolute;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.2);
    margin: -20px;
    display: none;
}

.flipbook-fillPreloader {
    position: fixed;
    width: 100%;
    height: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    pointer-events: none;
}

.flipbook-fillPreloader img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    -webkit-transition: all 0.11s ease-out;
    -moz-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}

.thumb-btn-close {
    right: 0;
    margin: 3px;
    border-radius: 2px;
    position: absolute;
    cursor: pointer;
    padding: 4px 6px;
    background: rgba(0, 0, 0, 0.6);
}

.thumb-btn-close:hover {
    background: rgba(0, 0, 0, 0.8);
}

.flipbook-noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flipbook-btn-active {
    background: rgba(128, 128, 128, 0.5);
}

.flipbook-move {
    cursor: move;
    /* fallback if move cursor is unsupported */
    cursor: -webkit-grab;
    cursor: grab;
}

.flipbook-page-item {
    bottom: auto;
    right: auto;
    position: absolute;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.flipbook-page-item iframe {
    width: 100%;
    height: 100%;
}

.textLayer mark {
    background: #ff0;
    color: transparent !important;
    padding: 0;
}

.textLayer .mark-blue {
    background: blue;
}

.textLayer .mark-red {
    background: red;
}

.textLayer .mark-green {
    background: green;
}

/* 
.flipbook-textLayer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: var(--flipbook-link-opacity);
    line-height: 1;
    color: #000;
    pointer-events: none;
}

.flipbook-textLayer br {
    user-select: none;
}

.flipbook-page-htmlContent * {
    max-width: initial;
}

.flipbook-page-htmlContent .pageClickArea {
    position: absolute;
    width: 5%;
    height: 100%;
    top: 0;
    cursor: pointer;
    draggable: none;
}

.pageClickAreaLeft {
    left: 0;
}

.pageClickAreaRight {
    right: 0;
}

.textLayer mark {
    background: #ff0;
    color: transparent;
}

.textLayer .mark-blue {
    background: blue;
}

.textLayer .mark-red {
    background: red;
}

.textLayer .mark-green {
    background: green;
}

.textLayer > span {
    color: transparent !important;
    position: absolute;
    white-space: pre;
    cursor: text;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0 0;
}

.textLayer > span::selection {
    color: transparent !important;
}

.textLayer > div {
    color: transparent !important;
    position: absolute;
    white-space: pre;
    cursor: text;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0 0;
}

.textLayer .highlight {
    margin: -1px;
    padding: 1px;
    background-color: rgb(180, 0, 170);
    border-radius: 4px;
}

.textLayer .highlight.begin {
    border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
    border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
    border-radius: 0;
}

.textLayer .highlight.selected {
    background-color: rgb(0, 100, 0);
}

.textLayer ::selection {
    background: rgb(0, 0, 255);
}

.textLayer ::-moz-selection {
    background: rgb(0, 0, 255);
}

.textLayer .endOfContent {
    background: initial;
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: default;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
}

.textLayer .endOfContent.active {
    top: 0;
}

.flipbook-disable-text-selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.annotationLayer section {
    position: absolute;
    padding: 0;
    margin: 0;
    background: none;
} */

.flipbook-page-html .pageClickArea {
    position: absolute;
    width: 50px;
    height: 1000px;
    top: 0;
    cursor: pointer;
    bottom: 0;
    z-index: 20;
}

.pageClickAreaLeft {
    left: 0;
}

.pageClickAreaRight {
    /* right: 0; */
}

.annotationLayer .linkAnnotation > a,
.annotationLayer .buttonWidgetAnnotation.pushButton > a,
.flipbook-page-item-link {
    position: absolute;
    font-size: 1em;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--flipbook-link-color);
    animation-duration: 2000ms;
    animation-delay: 500ms;
    animation-name: flashLinks;
    opacity: var(--flipbook-link-opacity);
}

.flipbook-page-auto-link {
    background: var(--flipbook-link-color);
    color: transparent !important;
    animation-duration: 2000ms;
    animation-delay: 500ms;
    animation-name: flashLinks;
    pointer-events: auto;
}

.flipbook-page-auto-link-hover {
    background: var(--flipbook-link-color-hover) !important;
    color: transparent !important;
}

@keyframes flashLinks {
    0% {
        background: var(--flipbook-link-color);
    }

    20% {
        background: var(--flipbook-link-color-hover);
    }

    100% {
        background: var(--flipbook-link-color);
    }
}

.flipbook-tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 300px;
    left: 300px;
    color: white;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 13px;
    font-weight: normal;
    white-space: nowrap;
    text-align: center;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    z-index: 10000;
}

.flipbook-submenu {
    position: absolute;
    right: 0;
    width: max-content;
    text-wrap: nowrap;
}

.flipbook-menuTop .flipbook-submenu {
    top: calc(100% + 5px);
}

.flipbook-menuBottom .flipbook-submenu {
    bottom: calc(100% + 5px);
}

.flipbook-add-note-rect {
    position: absolute;
    pointer-events: none;
}

.add-note-btn {
    pointer-events: auto;
}

.flipbook-add-note-rect .add-note-btn {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 0.25em;
    color: white;
    padding: 0.3em 1em;
    text-shadow: 0 1px 0 #000;
    font-weight: normal;
    white-space: nowrap;
    text-align: center;
    z-index: 10000;
    left: 50%;
    transform: translateX(-50%) translateY(-120%);
    pointer-events: auto;
    cursor: pointer;
    position: absolute;
    opacity: 0;
}

.flipbook-add-note-rect.hover .add-note-btn {
    opacity: 1;
    pointer-events: auto;
}

.flipbook-note-display {
    width: 230px;
    height: 125px;
    position: absolute;
    background-color: #fafafa;
    -webkit-box-shadow: 0 2px 10px 0 rgb(27 47 73 / 30%);
    box-shadow: 0 2px 10px 0 rgb(27 47 73 / 30%);
    border-radius: 5px;
    pointer-events: auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.flipbook-note-display .note-content {
    height: 76px;
    position: absolute;
    width: 210px;
    margin: 11px 15px 5px 15px;
    overflow: hidden;
}

.flipbook-note-display .note-article {
    font-family: inherit;
    resize: none;
    font-size: 14px;
    width: 200px;
    height: 76px;
    position: absolute;
    left: 0;
    top: 0;
    border-width: 1px;
    border-style: solid;
    padding: 0;
    color: rgb(89, 97, 104);
    border-color: transparent;
    background-color: transparent;
    outline: none !important;
}

.flipbook-note-display .note-article:focus {
    box-shadow: none;
}

.flipbook-note-display .note-delete-button .svg-icon {
    width: 22px;
    height: 22px;
    fill: #a6aaad;
}

.flipbook-note-display .note-button {
    float: right;
    font-size: 22px;
    line-height: 22px;
    height: 22px;
    color: #8d8d8d;
    cursor: pointer;
    text-align: center;
    margin: 5px 11px;
}

.flipbook-note-display .note-footer {
    height: 37px;
    width: 100%;
    margin: 0;
    bottom: 0;
    position: absolute;
}

.flipbook-page-note {
    pointer-events: auto;
    cursor: pointer;
}

.annotationLayer .linkAnnotation > a:hover,
.annotationLayer .buttonWidgetAnnotation.pushButton > a:hover,
.flipbook-page-item-link:hover {
    background: var(--flipbook-link-color-hover) !important;
    color: transparent !important;
}

.flipbook-page-auto-link:hover {
    background: var(--flipbook-link-color-hover) !important;
    color: transparent !important;
}

.flipbook-note-display {
    opacity: 1;
}

.flipbook-spotlight-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.88);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10002;
}

.flipbook-spotlight-overlay img {
    max-width: 80%;
    max-height: 80%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.flipbook-spotlight-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 2px;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 24px;
    height: 24px;
    z-index: 1;
}

.flipbook-spotlight-close-button:hover {
    opacity: 0.7;
}

.flipbook-spotlight-caption-container {
    color: white;
    text-align: center;
    font-size: 16px;
    line-height: 1.6;
    padding: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    margin-top: 10px;
}

.flipbook-spotlight-title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
}

.flipbook-spotlight-description {
    font-size: 14px;
    font-weight: normal;
}

.flipbook-page-html {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
}

.flipbook-page-html .htmlContent {
    position: absolute;
    z-index: 3;
}

.flipbook-page-html .textLayer {
    position: absolute;
    text-align: initial;
    inset: 0;
    overflow: hidden;
    opacity: 0.25;
    line-height: 1;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    forced-color-adjust: none;
    transform-origin: 0 0;
    z-index: 2;
    font-weight: initial;
}

.flipbook-page-html .textLayer :is(span, br) {
    color: transparent !important;
    position: absolute;
    white-space: pre;
    cursor: text;
    transform-origin: 0% 0%;
}

/* Only necessary in Google Chrome, see issue 14205, and most unfortunately
* the problem doesn't show up in "text" reference tests. */
.flipbook-page-html .textLayer span.markedContent {
    top: 0;
    height: 0;
}

.flipbook-page-html .textLayer .highlight {
    margin: -1px;
    padding: 1px;
    background-color: var(--highlight-bg-color);
    border-radius: 4px;
}

.flipbook-page-html .textLayer .highlight.appended {
    position: initial;
}

.flipbook-page-html .textLayer .highlight.begin {
    border-radius: 4px 0 0 4px;
}

.flipbook-page-html .textLayer .highlight.end {
    border-radius: 0 4px 4px 0;
}

.flipbook-page-html .textLayer .highlight.middle {
    border-radius: 0;
}

.flipbook-page-html .textLayer .highlight.selected {
    background-color: var(--highlight-selected-bg-color);
}

.flipbook-page-html .textLayer ::-moz-selection {
    background: blue;
    background: AccentColor; /* stylelint-disable-line declaration-block-no-duplicate-properties */
}

.flipbook-page-html .textLayer ::selection {
    background: blue;
    background: AccentColor; /* stylelint-disable-line declaration-block-no-duplicate-properties */
}

/* Avoids https://github.com/mozilla/pdf.js/issues/13840 in Chrome */
.flipbook-page-html .textLayer br::-moz-selection {
    background: transparent;
}
.flipbook-page-html .textLayer br::selection {
    background: transparent;
}

.flipbook-page-html .textLayer .endOfContent {
    display: block;
    position: absolute;
    inset: 100% 0 0;
    z-index: -1;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.flipbook-page-html .textLayer .endOfContent.active {
    top: 0;
}

:root {
    --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
    --input-focus-border-color: Highlight;
    --input-focus-outline: 1px solid Canvas;
    --input-unfocused-border-color: transparent;
    --input-disabled-border-color: transparent;
    --input-hover-border-color: black;
    --link-outline: none;
}

@media screen and (forced-colors: active) {
    :root {
        --input-focus-border-color: CanvasText;
        --input-unfocused-border-color: ActiveText;
        --input-disabled-border-color: GrayText;
        --input-hover-border-color: Highlight;
        --link-outline: 1.5px solid LinkText;
        --hcm-highligh-filter: invert(100%);
    }
    .annotationLayer .textWidgetAnnotation :is(input, textarea):required,
    .annotationLayer .choiceWidgetAnnotation select:required,
    .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:required {
        outline: 1.5px solid selectedItem;
    }

    .annotationLayer .linkAnnotation:hover {
        -webkit-backdrop-filter: var(--hcm-highligh-filter);
        backdrop-filter: var(--hcm-highligh-filter);
    }

    .annotationLayer .linkAnnotation > a:hover {
        opacity: 0 !important;
        background: none !important;
        box-shadow: none;
    }

    .annotationLayer .popupAnnotation .popup {
        outline: calc(1.5px * var(--scale-factor)) solid CanvasText !important;
        background-color: ButtonFace !important;
        color: ButtonText !important;
    }

    .annotationLayer .highlightArea:hover::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-backdrop-filter: var(--hcm-highligh-filter);
        backdrop-filter: var(--hcm-highligh-filter);
        content: '';
        pointer-events: none;
    }

    .annotationLayer .popupAnnotation.focused .popup {
        outline: calc(3px * var(--scale-factor)) solid Highlight !important;
    }
}

.annotationLayer {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transform-origin: 0 0;
    z-index: 3;
}

.annotationLayer[data-main-rotation='90'] .norotate {
    transform: rotate(270deg) translateX(-100%);
}
.annotationLayer[data-main-rotation='180'] .norotate {
    transform: rotate(180deg) translate(-100%, -100%);
}
.annotationLayer[data-main-rotation='270'] .norotate {
    transform: rotate(90deg) translateY(-100%);
}

.annotationLayer canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.annotationLayer section {
    position: absolute;
    text-align: initial;
    pointer-events: auto;
    box-sizing: border-box;
    transform-origin: 0 0;
}

.annotationLayer .linkAnnotation {
    outline: var(--link-outline);
}

.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a {
    position: absolute;
    font-size: 1em;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton):not(.hasBorder) > a:hover {
    /* opacity: 0.2;
    background-color: rgba(255, 255, 0, 1);
    box-shadow: 0 2px 10px rgba(255, 255, 0, 1); */
}

.annotationLayer .linkAnnotation.hasBorder:hover {
    background-color: rgba(255, 255, 0, 0.2);
}

.annotationLayer .hasBorder {
    background-size: 100% 100%;
}

.annotationLayer .textAnnotation img {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea),
.annotationLayer .choiceWidgetAnnotation select,
.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
    background-image: var(--annotation-unfocused-field-background);
    border: 2px solid var(--input-unfocused-border-color);
    box-sizing: border-box;
    font: calc(9px * var(--scale-factor)) sans-serif;
    height: 100%;
    margin: 0;
    vertical-align: top;
    width: 100%;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):required,
.annotationLayer .choiceWidgetAnnotation select:required,
.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:required {
    outline: 1.5px solid red;
}

.annotationLayer .choiceWidgetAnnotation select option {
    padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
    border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
    resize: none;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea)[disabled],
.annotationLayer .choiceWidgetAnnotation select[disabled],
.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input[disabled] {
    background: none;
    border: 2px solid var(--input-disabled-border-color);
    cursor: not-allowed;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:hover {
    border: 2px solid var(--input-hover-border-color);
}
.annotationLayer .textWidgetAnnotation :is(input, textarea):hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer .buttonWidgetAnnotation.checkBox input:hover {
    border-radius: 2px;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):focus,
.annotationLayer .choiceWidgetAnnotation select:focus {
    background: none;
    border: 2px solid var(--input-focus-border-color);
    border-radius: 2px;
    outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) :focus {
    background-image: none;
    background-color: transparent;
}

.annotationLayer .buttonWidgetAnnotation.checkBox :focus {
    border: 2px solid var(--input-focus-border-color);
    border-radius: 2px;
    outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.radioButton :focus {
    border: 2px solid var(--input-focus-border-color);
    outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after,
.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
    background-color: CanvasText;
    content: '';
    display: block;
    position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
    height: 80%;
    left: 45%;
    width: 1px;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before {
    transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
    transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
    border-radius: 50%;
    height: 50%;
    left: 30%;
    top: 20%;
    width: 50%;
}

.annotationLayer .textWidgetAnnotation input.comb {
    font-family: monospace;
    padding-left: 2px;
    padding-right: 0;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
    /*
* Letter spacing is placed on the right side of each character. Hence, the
* letter spacing of the last character may be placed outside the visible
* area, causing horizontal scrolling. We avoid this by extending the width
* when the element has focus and revert this when it loses focus.
*/
    width: 103%;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.annotationLayer .fileAttachmentAnnotation .popupTriggerArea {
    height: 100%;
    width: 100%;
}

.annotationLayer .popupAnnotation {
    position: absolute;
    font-size: calc(9px * var(--scale-factor));
    pointer-events: none;
    width: -moz-max-content;
    width: max-content;
    max-width: 45%;
    height: auto;
}

.annotationLayer .popup {
    background-color: rgba(255, 255, 153, 1);
    box-shadow: 0 calc(2px * var(--scale-factor)) calc(5px * var(--scale-factor)) rgba(136, 136, 136, 1);
    border-radius: calc(2px * var(--scale-factor));
    outline: 1.5px solid rgb(255, 255, 74);
    padding: calc(6px * var(--scale-factor));
    cursor: pointer;
    font: message-box;
    white-space: normal;
    word-wrap: break-word;
    pointer-events: auto;
}

.annotationLayer .popupAnnotation.focused .popup {
    outline-width: 3px;
}

.annotationLayer .popup * {
    font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .popup > .header {
    display: inline-block;
}

.annotationLayer .popup > .header h1 {
    display: inline;
}

.annotationLayer .popup > .header .popupDate {
    display: inline-block;
    margin-left: calc(5px * var(--scale-factor));
    width: -moz-fit-content;
    width: fit-content;
}

.annotationLayer .popupContent {
    border-top: 1px solid rgba(51, 51, 51, 1);
    margin-top: calc(2px * var(--scale-factor));
    padding-top: calc(2px * var(--scale-factor));
}

.annotationLayer .richText > * {
    white-space: pre-wrap;
    font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .popupTriggerArea {
    cursor: pointer;
}

.annotationLayer section svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.annotationLayer .annotationTextContent {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    color: transparent !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
}

.annotationLayer .annotationTextContent span {
    width: 100%;
    display: inline-block;
}

.annotationLayer svg.quadrilateralsContainer {
    contain: strict;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

/* print.js https://github.com/crabbly/Print.js/releases/tag/v1.5.0 */
.printModal {
    font-family: sans-serif;
    display: flex;
    text-align: center;
    font-weight: 300;
    font-size: 30px;
    left: 0;
    top: 0;
    position: absolute;
    color: #045fb4;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 100%, 0.9);
}

.printClose {
    position: absolute;
    right: 10px;
    top: 10px;
}

.printClose:before {
    content: '\00D7';
    font-family:
        Helvetica Neue,
        sans-serif;
    font-weight: 100;
    line-height: 1px;
    padding-top: 0.5em;
    display: block;
    font-size: 2em;
    text-indent: 1px;
    overflow: hidden;
    height: 1.25em;
    width: 1.25em;
    text-align: center;
    cursor: pointer;
}

.printSpinner {
    margin-top: 3px;
    margin-left: -40px;
    position: absolute;
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 2px solid #045fb4;
    border-radius: 50%;
    animation: spin 0.75s linear infinite;
}

.printSpinner:after,
.printSpinner:before {
    left: -2px;
    top: -2px;
    display: none;
    position: absolute;
    content: '';
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
}

.printSpinner,
.printSpinner:after,
.printSpinner:before {
    display: inline-block;
    border-color: #045fb4 transparent transparent;
    animation-duration: 1.2s;
}

.printSpinner:before {
    transform: rotate(120deg);
}

.printSpinner:after {
    transform: rotate(240deg);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1turn);
    }
}
